<template>
  <div class="main-content">
    <div class="conetnt-top">
      <div class="content-top-title">
        <span class="top-title">客户分析</span>
      </div>
      <div class="content-top-form">
        <el-form
          :model="searchForm"
          :rules="searchFormRules"
          ref="ruleForm"
          size="medium"
          class="top-form"
        >
          <el-form-item prop="deptCodeList" class="form-item">
            <el-cascader
              ref="deptCascaderRef"
              collapse-tags
              v-model="deptData"
              :options="deptOptions"
              :props="deptCascaderProps"
              placeholder="请选择组织架构"
              filterable
              clearable
              show-all-levels
              @change="changeCas"
              class="form-item-components"
            >
              <template #default="{ data }">
                <span>{{ data.deptName }}</span>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item prop="regionData" class="form-item">
            <el-cascader
              ref="hospitalCascaderRef"
              v-model="regionData"
              collapse-tags
              clearable
              filterable
              :options="hospitalOptions"
              :props="hospitalProps"
              @change="(node)=>onChangeHospital(node,'hospital')"
              @visible-change="onDeptVisibleChange"
              placeholder="请选择地区"
              class="form-item-components"
            >
          </el-cascader>
          </el-form-item>
          <el-form-item prop="hospitalIdList" class="form-item">
            <el-select
              v-model="searchForm.hospitalIdList"
              placeholder="请选择医院"
              filterable
              multiple
              clearable
              collapse-tags
              @visible-change="onHospitalVisibleChange"             
              class="form-item-components"
            >
              <el-option
                v-for="(item,index) in hospitalList"
                :key="index.hospitalId"
                :label="item.hospitalName"
                :value="item.hospitalId"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="doctorName" class="form-item">
            <el-input
              v-model="searchForm.doctorName"
              clearable
              class="form-item-components"
              placeholder="请输入医生"
              maxlength="200"
            ></el-input>
          </el-form-item>
          <el-form-item prop="personnelIdentityCodeList" class="form-item" v-show="showAllFlag">
            <el-select
              v-model="searchForm.personnelIdentityCodeList"
              placeholder="请选择队伍属性"
              filterable
              clearable
              multiple             
              class="form-item-components"
            >
              <el-option
                v-for="(item,index) in personnelList"
                :key="index"
                :label="item.label"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item prop="firstSendStartMonth" class="form-item" v-show="showAllFlag">
            <el-date-picker
              v-model="value1"
              type="monthrange"
              range-separator="至"
              start-placeholder="首次送单开始日期"
              end-placeholder="首次送单结束日期"
              value-format="yyyy-MM-dd"
              class="form-item-components"
            >
            </el-date-picker>
          </el-form-item>

          <!-- <el-form-item prop="name" class="form-item">
            <el-cascader
              v-model="value"
              :options="options"
              placeholder="请选择组织架构"
              class="form-item-components"
            ></el-cascader>
          </el-form-item>
          <el-form-item prop="personnelIdentity" class="form-item">
            <el-select
              v-model="searchForm.personnelIdentity"
              placeholder="请选择身份"
              class="form-item-components"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item prop="year" class="form-item" v-show="showAllFlag">
            <el-date-picker
              class="form-item-components"
              v-model="searchForm.year"
              type="year"
              clearable
              value-format="yyyy"
              prefix-icon="require-icon"
              placeholder="请选择销量年份"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div class="search-btns">
        <el-button size="small" @click="handleExport" :loading="exportLoading">导出</el-button>
        <el-button type="primary" size="small" @click="handleSearch"
          >查询</el-button
        >
        <span class="expand-btn" v-if="showAllFlag" @click="onChangeShowStatus">
          <span>收起</span>
          <span><i class="el-icon-arrow-up"></i></span>
        </span>
        <span class="expand-btn" v-if="!showAllFlag" @click="onChangeShowStatus">
          <span>展开</span>
          <span><i class="el-icon-arrow-down"></i></span>
        </span>
      </div>
    </div>
    <div class="content-middle" style="padding-top:0;padding-bottom:0;">
      <!-- <div class="middle-data">
        <div class="middle-data-item">
          <div class="item-title">客户总数</div>
          <div class="item-number">29000</div>
        </div>
        <div class="middle-data-item">
          <div class="item-title">总绑定率</div>
          <div class="item-number">98.12%</div>
        </div>
        <div class="middle-data-item">
          <div class="item-title">开单率</div>
          <div class="item-number">98.24%</div>
        </div>
        <div class="middle-data-item">
          <div class="item-title">总销量（元）</div>
          <div class="item-number">102349</div>
        </div>
      </div> -->
      <div class="middle-table">
        <el-table
          :data="tableData"
          style="width: 100%"
          :height="height"
          ref="tableRef"
          :header-cell-style="{ background: '#F5F7FA', color: '#5C6474' }"
          :header-row-class-name="setHeaderCellName"
        >
          <el-table-column label="基本信息" class="base" align="center">
            <el-table-column
              :label="item.value"
              v-for="(item, index) in tabaleColumns_base"
              :key="index"
              :align="item.align"
              :min-width="item.width||100"
            >
              <template #default="scope">
                <span>{{ handleFormattDate(scope, item) }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="销售额（万元）" align="center">
            <el-table-column
              :label="item.value"
              v-for="(item, index) in tabaleColumns_sale"
              :key="index"
              :align="item.align"
              :min-width="item.width||80"
            >
              <template #default="{row}">
                <span>{{ row[item.props] }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="分级" align="center">
            <el-table-column
              :label="item.value"
              v-for="(item, index) in tabaleColumns_grade"
              :key="index"
              :align="item.align"
              :min-width="item.width||80"
            >
              <template #default="{row}">
                <span>{{ row[item.props] }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <!-- <el-table-column>
            <template #default="scope">
              <div slot="empty" style="text-align: left">暂无数据121212</div>
            </template>
          </el-table-column> -->
          <div slot="empty">
            <div style="height: 68px">
              <img
                src="../../../assets/nodata.png"
                style="width: 68px; height: 68px"
              />
            </div>
            <div style="margin-top: -10px">暂无数据</div>
          </div>
        </el-table>
        <div class="middle-pagination">
          <el-pagination
            :current-page="searchForm.pageNum"
            :page-sizes="page.sizes"
            :page-size="searchForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="searchForm.total"
            @current-change="onChangePageNum"
            @size-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  customerPermissions,
  getNowFormatDate,
} from "@/utils";
import msg from "@/utils/msg";
export default {
  name: "Company",
  data() {
    // setTimeout(() => {
    //   this.height = window.innerHeight - this.$refs.tableRef.$el.offsetTop - 50;
    // }, 100);
    return {
      height: "300px",
      tabaleColumns_base: [
        {
          value: "首次送单月",
          props: "firstSendOrderMonth",
          hasDefault: true,
          default: "未开单",
          align: "left",
          width:"140"
        },
        {
          value: "营销部",
          props: "deptLevelTwo",
          align: "left",
          width:"110"
        },
        {
          value: "医院销售部",
          props: "deptLevelThree",
          align: "left",
          width:"110"
        },
        {
          value: "大区",
          props: "deptLevelFour",
          align: "left",
        },
        {
          value: "地区",
          props: "deptLevelFive",
          align: "left",
        },
        {
          value: "销售OA",
          props: "bindOaAccount",
          align: "left",
        },
        {
          value: "销售姓名",
          props: "bindOaName",
          align: "left",
        },
        {
          value: "队伍属性",
          props: "personnelIdentity",
          align: "left",
        },
        {
          value: "医院",
          props: "hospitalName",
          align: "left",
          width: "160"
        },
        {
          value: "医院ID",
          props: "hospitalId",
          align: "left",
        },
        {
          value: "送检医生",
          props: "doctorName",
          align: "left",
        },
        {
          value: "医生ID",
          props: "doctorId",
          align: "left",
        },
        {
          value: "产品线",
          props: "produceLineName",
          align: "left",
        },
        {
          value: "销量年份",
          props: "year",
          align: "left",
        },
      ],
      tabaleColumns_sale:[],
      tabaleColumns_grade:[],
      tableData: [],
      searchForm: {
        year: "",
        firstSendStartMonth: "",
        firstSendEndMonth: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
        personnelIdentityCodeList:[],
        deptCodeList: [],
        hospitalIdList:[],
        doctorName:""
      },
      firstSendStartMonth: "",
      firstSendEndMonth: "",
      searchFormRules: {
        year: [{ required: true, message: "请选择年", trigger: "change" }],
      },
      value: [],
      doctorOptions: [],
      regionData: [],
      hospitalOptions: [],
      options: [],
      value1: [],
      cascaderProps: {
        multiple: true,
        label: "regionName",
        value: "regionCode",
        checkStrictly: true,
        lazy: true,
        lazyLoad: this.lazyLoad,
      },
      hospitalProps: {
        multiple: true,
        label: "areaName",
        value: "areaCode",
        checkStrictly: false,
      },
      value3: "",
      isAllFlag: false,
      deptCascaderProps: {
        checkStrictly: true,
        multiple: true,
        value: "deptCode",
        label: "deptName",
        children: "subList",
        disabled: "cantSelect",
      },
      deptData: "",
      deptOptions: [],
      personnelList: [],
      newList:[],
      hospitalList:[],
      areaCodeList:[],
      cityCodeList:[],
      provinceCodeList:[],
      needRenewRegion:false,
      needRenewHospital:false,
      exportLoading:false,
      showAllFlag:true,
      page: {
        current: 1, // 当前在第几页
        sizes: [10, 20, 50, 100], // 每页显示多少条选择数组
        size: 10, // 每页显示多少条
        total: 0, // 共几条
      },
    };
  },
  mounted() {
    this.setTableColumns();
    this.setHeight();
    this.getDeptList();
    this.getPersonnelList();
    this.getList();
    this.setCurrentYear();
  },
  methods: {
    handleFormattDate(scope, item) {
      if (item.props === "firstSendOrderMonth") {
        return item.hasDefault && !scope.row[item.props]
          ? item.default
          : scope.row.firstSendOrderMonthStr;
      }
      return scope.row[item.props];
    },
    // 进入页面后，销量年份默认选中当前年份
    setCurrentYear(){
      const _year = new Date().getFullYear()
      this.searchForm.year = _year + ''
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.getList();
    },
    // 切换到第几页
    onChangePageNum(page) {
      this.searchForm.pageNum = page;
      this.getList();
    },
    handleExport() {
      this._dealSearchForm();
      const parms = Object.assign({}, this.searchForm, {
          pageNum: 1,
          pageSize: 100000,
      });

      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.exportLoading = true
          this.axios.post("CUSTOMERANALYSISEXPORT", parms,true,{showLoading:false}).then((result) => {
            console.log("result instanceof Blob", result);
            if (result instanceof Blob) {
              const blob = new Blob([result]);
              console.log(blob);
              let time = getNowFormatDate();
              const fileName = `客户分析${time}` + ".xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
              this.exportLoading = false
              msg.success('导出成功')
            } else {
              this.exportLoading = false;
              msg.error("导出失败！");
            }
          });
        }
      });
    },
    setTableColumns() {
      const mouthObj = {
        1: "january",
        2: "february",
        3: "march",
        4: "april",
        5: "may",
        6: "june",
        7: "july",
        8: "august",
        9: "september",
        10: "october",
        11: "november",
        12: "december",
      };
      let arr_sale = Array(12)
        .fill(1)
        .map((item, index) => {
          let mouth = item + index;
          return {
            value: `${mouth}月`,
            props: `${mouthObj[mouth]}Sales`,
            align: "right",
          };
        });
      let arr_grade = Array(12)
        .fill(1)
        .map((item, index) => {
          let mouth = item + index;
          return {
            value: `${mouth}月`,
            props: `${mouthObj[mouth]}Grade`,
            align: "right",
          };
        });
        this.tabaleColumns_sale = arr_sale;
        this.tabaleColumns_grade = arr_grade;
    },
    handleSearch() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.searchForm.pageNum = 1;
          this._dealSearchForm();
          this.getList();
        }
      });
    },

    _dealSearchForm() {
      this.searchForm.firstSendStartMonth =
            Array.isArray(this.value1) && this.value1[0]
              ? this.handleFormateDate(this.value1[0])
              : "";
      this.searchForm.firstSendEndMonth =
        Array.isArray(this.value1) && this.value1[1]
          ? this.handleFormateDate(this.value1[1])
          : "";
      this.searchForm.provinceCodeList = this.provinceCodeList
      this.searchForm.cityCodeList = this.cityCodeList
      this.searchForm.areaCodeList = this.areaCodeList
    },

    handleFormateDate(val) {
      const dArr = val?.split("-");
      const year = dArr ? dArr[0] : "";
      const month = dArr ? dArr[1] : "";
      return year && month ? `${year}${month}` : "";
    },
    getList() {
      this.axios.post("CUSTOMERANALYSIS", this.searchForm).then((result) => {
        this.tableData = result.data.list;
        this.searchForm.total = result.data.total;
      });
    },
    // 选中地区变更
    onChangeHospital(v,type) {
      console.log('onChangeHospital',v)
      // return
      this.$nextTick(() => {
        const checkNodes = this.$refs.hospitalCascaderRef.getCheckedNodes();
        const _len = checkNodes.length
        this.newList = []
        this.resetRegionList();
        this.resetHospitalList();
        for(let i=0;i<_len;i++){
          let newItem = checkNodes[i]
          this.setNewLists(newItem)
        }
        // console.log('newList setNewLists',this.newList)
        // console.log('newList provinceCodeList',this.provinceCodeList)
        // console.log('newList cityCodeList',this.cityCodeList)
        // console.log('newList areaCodeList',this.areaCodeList)

        // 变更地区，则需要更新医院数据
        this.needRenewHospital = true;
      });
    }, 

    // 根据已选地区获取医院数据
    _getHospitalData(){
      let params = {
        areaCodeList: this.areaCodeList,
        cityCodeList:this.cityCodeList,
        provinceCodeList:this.provinceCodeList
      };
      console.log('getHospitalData12',params);
      let test = [];
      this.axios
        .get("CUSTOMERHOSPITALLIST", params)
        .then((result) => {
          this.needRenewHospital = false;
          if (result.code === 0) {
            // test = customerPermissions(result.data, params.levelType);
            test = result.data;
            this.hospitalList = test;
          } else {
            this.hospitalList = []
          }
        })
        .catch(() => {
          
        });
    },

    // 重置地区列表数据
    resetRegionList(){
      this.areaCodeList = []
      this.cityCodeList = []
      this.provinceCodeList = []
    },
    // 重置医院已选择数据
    resetHospitalList(){
      this.searchForm.hospitalIdList = []
      this.hospitalList = []
    },

    setNewLists(item) {
      console.log('checkNewLists',item)
      if(item.level == 1){
        if(!this._isInList(this.provinceCodeList,item.value)){
          this.provinceCodeList.push(item.value)
        }
        
        return
      }else if(item.level === 2){
        let _item = item.parent
        if(_item.checked){
          if(!this._isInList(this.provinceCodeList,_item.value)){
            this.provinceCodeList.push(_item.value)
          }
        }else {
          if(!this._isInList(this.cityCodeList,item.value)){
            this.cityCodeList.push(item.value)
          }
        }
      }else if(item.level === 3){
        let _item = item.parent
        if(_item.checked){
          this.setNewLists(_item)
        }else {
          if(!this._isInList(this.areaCodeList,item.value)){
            this.areaCodeList.push(item.value)
          }

        }
      }
    }, 
    
    _isInList(list,value){
      console.log('_isInLsst list',list)
      console.log('_isInLIst value',value)
      return list.includes(value)
    },

    
    // 地区选择器展开时，如果没有选择组织架构，需要给出提示
    onDeptVisibleChange(visibleFlag){
      if(visibleFlag && this.searchForm.deptCodeList.length === 0){
        msg.error('请选择组织架构')
        return
      }
      if(visibleFlag && this.needRenewRegion){
        this.getProvinceCityAreaList()
      }
    },
    // 医院选择器展开时，如果没有选择地区，需要给出提示
    onHospitalVisibleChange(visibleFlag){
      if(visibleFlag && this.regionData.length === 0){
        msg.error('请选择地区')
        return 
      }
      if(visibleFlag && this.needRenewHospital){
        this._getHospitalData()
      }
    },

    getDeptList() {
      this.axios.get("CUSTOMERAUTHDEPTLIST","",true,{showLoading:false}).then((result) => {
        if(result.code === 0){
          this.deptOptions = this.formatTreeData(result.data);
        }
      });
    },
    formatTreeData(treeList) {
      const result = treeList.map((item) => {
        return {
          deptCode: item.deptCode,
          deptName: item.deptName,
          fullPathCode: item.fullPathCode,
          fullPathName: item.fullPathName,
          level: item.level,
          cantSelect: !item.selectFlag,
          parentCode: item.parentCode,
          selectFlag: item.selectFlag,
          subList: item.subList ? this.formatTreeData(item.subList) : null,
        };
      });
      return result;
    },
    setDisableTreeNodes(level, treeList) {
      const result = treeList.map((item) => {
        console.log('setDisable',item)
        return {
          deptCode: item.deptCode,
          deptName: item.deptName,
          fullPathCode: item.fullPathCode,
          fullPathName: item.fullPathName,
          level: item.level,
          cantSelect:
            (item.level === level || level === null) && item.selectFlag === true
              ? false
              : true,
          parentCode: item.parentCode,
          selectFlag: item.selectFlag,
          subList: item.subList
            ? this.setDisableTreeNodes(level, item.subList)
            : null,
        };
      });
      return result;
    },
    changeCas(v) {
      console.log('changeCas',v)
      this.$nextTick(() => {
        const checkNodes = this.$refs.deptCascaderRef.getCheckedNodes();
        this.deptOptions = checkNodes[0]
          ? this.setDisableTreeNodes(
              checkNodes[0]["data"]["level"],
              this.deptOptions
            )
          : this.setDisableTreeNodes(null, this.deptOptions);
        const deptCodeList = v.map((item, index) => {
          return item[item.length - 1];
        });
        console.log('deptCodeList',deptCodeList)
        this.searchForm.deptCodeList = deptCodeList;        
        this.hospitalOptions = []
        this.resetRegionList();
        this.resetHospitalList();
        this.regionData = [];
        // 需要更新地区数据
        this.needRenewRegion = true;
      });
    },
    // 获取大区关联的省市地区
    getProvinceCityAreaList() {
      const deptCodeList = this.searchForm.deptCodeList;
      if(deptCodeList == null || deptCodeList.length === 0){
        this.hospitalOptions = []
        return
      }
      this.$axios.get("CUSTOMERPROVINCECITYAREA",{deptCodeList}).then(result=>{
        console.log('result',result)
        // 已经更新了就不需要再更新了
        this.needRenewRegion = false;
        const {code,data} = result
        console.log('regionData',JSON.stringify(data))
        if(code === 0){
          this.hospitalOptions = this._dealRegionData(data)
          console.log('hospitalOptions',this.hospitalOptions)
        }
      })
    }, 
    // 处理地区级联数据
    _dealRegionData(list) {
      let _list = []
      _list = list.map((item,index)=>{
        let _item = {}
        _item.areaCode = item.provinceCode
        _item.areaName = item.provinceName
        _item.children = item.cityList.map((cityItem)=>{
          let _cityItem = {}
          _cityItem.areaCode = cityItem.cityCode
          _cityItem.areaName = cityItem.cityName
          _cityItem.children = cityItem.areaList
          console.log('_cityItem',_cityItem)
          return _cityItem
        })
        return _item
      })
      return _list
    },
    // 查询队伍属性枚举
    getPersonnelList() {
      this.axios.get("CUSTOMERPERSONNEPERSONNELLLIST","").then((result) => {
        console.log('getPersonnel',result)
        const {code,data} = result;
        console.log('22222',Object.keys(data))
        if(code === 0){
          this.personnelList = Object.keys(data).map(item=>{
            let _item = {}
            _item.id = parseInt(item)
            _item.label = data[item]
            return _item
          })
        }else {
          this.personnelList = []
        }
        
      });
    },
    setHeight() {
      let that = this;
      that.$nextTick(()=>{
        let screenHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
        const top = this.$refs.tableRef.$el.getBoundingClientRect().top;
        that.height = screenHeight - top - 56;
      })
      
    },
    lazyLoad(node, resolve) {
      this.getData(node, resolve);
    },

    async getData(node, resolve) {
      let that = this;
      let { level, value } = node;
      if (value === "all") {
        resolve([]);
        return;
      }
      let params = {
        levelType: parseInt(level + 1),
        regionCode: value,
        type: 1,
      };
      console.log(params);
      let test = [];
      await this.axios
        .post("HospitalOrDoctor", params)
        .then((result) => {
          if (result.retCode == "200") {
            test = customerPermissions(result.data, params.levelType);
            this.$nextTick(() => {
              const nodes = Array.from(test).map((item) => {
                return {
                  regionCode: item.regionCode,
                  regionName: `${item.regionName}`,
                  leaf: level >= 3,
                };
              });
              resolve(nodes);
            });
          } else {
            msg.error(result.msg);
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },

    // 切换展开收起状态
    onChangeShowStatus(){
      this.showAllFlag = !this.showAllFlag
      this.setHeight();
    },

    setHeaderCellName({row,rowIndex}){
        return 'header-cell'
    }  


  },
};
</script>
<style scoped>
.conetnt-top {
  border-radius: 8px;
  background: #ffffff;
  padding: 12px 24px;
}

.content-top-title {
  padding-bottom: 19px;
}

.top-title {
  font-weight: 500;
  color: #303133;
  font-size: 18px;
  padding-right: 13px;
}

.top-desc {
  color: #606266;
  font-size: 14px;
}

.el-icon-question {
  color: #c0c4cc;
}

.top-form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.form-item {
  width: 25%;
  padding: 0 12px;
  padding-left: 0;
  margin-bottom: 12px;
}

.form-item-components {
  width: 100%;
}

.form-item-components >>> .require-icon:before {
  content: "*";
  color: #f56c6c;
  margin-left: 10px;
  padding-top: 3px;
  height: 100%;
  display: block;
}

.search-btns {
  width: 100%;
  padding-right: 12px;
  text-align: right;
}

.content-middle {
  padding: 24px;
  margin-top: 24px;
  background: #ffffff;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  border-radius: 8px;
}

.middle-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.middle-data-item {
  width: 24%;
  padding: 24px;
  border-radius: 8px;
}

.middle-data-item:nth-child(1) {
  background: #c6e6f5;
}

.middle-data-item:nth-child(2) {
  background: #c6e6f5;
}

.middle-data-item:nth-child(3) {
  background: #cbedea;
}

.middle-data-item:nth-child(4) {
  background: #f3d9ee;
}
.item-title {
  font-size: 14px;
  font-weight: 400;
  color: #606266;
}
.item-number {
  padding-top: 16px;
  font-size: 28px;
  font-weight: 500;
  color: #303133;
}

.middle-table {
  padding-top: 23px;
  width: 100%;
}

.middle-pagination {
  text-align: right;
}
</style>
<style lang="scss" scoped src="./index.scss"></style>