/*
 * @Description: 弹框信息处理
 * @Author: yinhua.zhu@simceredx.com
 * @Date: 2021-10-18 15:48:58
 * @LastEditTime: 2023-12-12 15:31:03
 * @LastEditors: tong.yu1@simceredx.com
 */

import { Message, Notification } from 'element-ui'
// 持续时间配置 1s
const duration = 5000
// 距离顶部偏移量，顶部菜单高度140px
// const offset = 100

const assignOptions = (val) => {
  return Object.assign({}, { duration }, { message: val })
}


const msg = {
  error(val) {
    Message.closeAll()
    Message.error(val)
  },
  errorLong(val) {
    Message.closeAll()
    Message.error(assignOptions(val))
  },
  success(val,duration=2000) {
    // duration默认值为2000，可在使用时按照需求变更
    // Message.closeAll()
    Notification.closeAll()
    // Message.success(val)
    Notification({
      title: '成功',
      message: val,
      type: 'success',
      duration,
      position: 'bottom-right' // top-right/top-left/bottom-right/bottom-left
    })
  },
  warning(val) {
    Message.closeAll()
    Message.warning(val)
  },
  info(val,duration=2000) {
    // Message.closeAll()
    Notification.closeAll()
    // Message.info(val)
    Notification({
      title: '信息',
      message: val,
      type: 'info',
      duration,
      position: 'bottom-right' // top-right/top-left/bottom-right/bottom-left
    })
  },
  customSuccess(val) {
    Message.closeAll()
    Message({
      message: val,
      type: 'success',
      iconClass: 'checked',
      customClass: 'toast-box success'
    })
  },
  customError(val) {
    Message.closeAll()
    Message({
      message: val,
      type: 'error',
      iconClass: 'error',
      customClass: 'toast-box error'
    })
  },
  closeMessage() {
    Message.closeAll()
  },
  blackInfo(val){
    Message.closeAll()
    Message({
      message: val,
      type: 'success',
      customClass:'small-msg',
      duration: 1000,
      offset: 300
    })
  }
}

export default msg
